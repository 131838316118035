body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Darker Grotesque",
        "Open Sans", "Segoe UI", "Roboto", "Helvetica Neue", "Oxygen", "Ubuntu",
        "Cantarell", "Fira Sans", "Droid Sans", "PT Sans", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, "Darker Grotesque", "Open Sans", "PT Sans",
        Menlo, Monaco, Consolas, monospace;
}

input[type="file"]::-ms-browse {
    background-color: #ec4e55;
    border: 5px solid #ec4e55;
    border-radius: 5px;
    padding: 10px;
    margin: 10px 10px 10px 0px;
    color: white;
}

input[type="file"]::-webkit-file-upload-button {
    background-color: #ec4e55;
    border: 5px solid #ec4e55;
    border-radius: 5px;
    padding: 10px;
    margin: 10px 10px 10px 0px;
    color: white;
    transition: 0.25s;
}

input[type="file"]::file-selector-button {
    background-color: #ec4e55;
    border: 5px solid #ec4e55;
    border-radius: 5px;
    padding: 10px;
    margin: 10px 10px 10px 0px;
    color: white;
    transition: 0.25s;
}

input[type="file"]::file-selector-button:hover {
    background-color: #c61f27;
    border: 5px solid #c61f27;
    cursor: pointer;
}
input[type="file"]::-ms-browse:hover {
    background-color: #c61f27;
    border: 5px solid #c61f27;
    cursor: pointer;
}

input[type="file"]::-webkit-file-upload-button:hover {
    background-color: #c61f27;
    border: 5px solid #c61f27;
    cursor: pointer;
}

input[type="file"]::file-selector-button:hover {
    background-color: #c61f27;
    border: 5px solid #c61f27;
    cursor: pointer;
}
